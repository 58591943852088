import React, { useEffect, useState, useRef } from 'react'
import logger from './MyLogger';
import call_backend_api from './APIHandler';

const RollingQuote = ({ shabat_info }) => {

  const renderTimes = useRef(0)
  const [currentElement, setCurrentElement] = useState(0);
  const [quotes, setQuotes] = useState([]);

    useEffect(() => {
      if (shabat_info === null || shabat_info === undefined){
        return
      }
      let parasha_record = shabat_info['parasha_record']
        const quoteHeaders = ['q1', 'q2', 'q3', 'q4', 'q5']
            var allQuotes = []
            for (var i = 0; i < quoteHeaders.length; i += 1) {
              var newQuote = parasha_record[quoteHeaders[i]]
              if (newQuote !== undefined && newQuote !== "" && !allQuotes.includes(newQuote)) {
                allQuotes.push(newQuote)
              }
            }
          setQuotes(allQuotes)
    }, [shabat_info])


    useEffect(() => {
      if ("geolocation" in navigator) {
        logger.log("GPS Available");
      }
      renderTimes.current += 1
      const intervalId = setInterval(() => {
        setCurrentElement((cur) => (cur + 1) % quotes.length);
      }, 5000);

      // Clear the interval when the component unmounts to avoid memory leaks
      return () => {
        clearInterval(intervalId);
      };

    }, [quotes]);


    return (
      <div className='text-base relative w-full h-16'>
        {quotes.map((item, index) => (
          <div key={item} className={`bg-[#ffffff60] h-16 w-full absolute top-0 transition-opacity ${index === currentElement ? 'opacity-100 delay-[800ms] duration-[2000ms]' : 'opacity-0 delay-0 duration-[1000ms]'} flex flex-col justify-center items-center`}  style={{/*fontSize: '2.2vh'*/}}> 
            <p>{item}</p>
          </div>
        ))}
      </div >
    )
  }

export default RollingQuote