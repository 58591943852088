import React, { createContext, useEffect, useState } from "react";
import logger from './MyLogger';
import { jwtDecode } from 'jwt-decode';
import call_backend_api from "./APIHandler";

const clientId = "427936102880-2kcq2secru3q9083kqf29hcnevvnke9n.apps.googleusercontent.com";

const LoginContext = createContext(); // Context object

const LoginProvider = ({ children }) => {
  const [googleUser, setGoogleUser] = useState(undefined);

  const openGoogleLoginDialog = () => {
    const params = new URLSearchParams({
      client_id: clientId,
      scope: 'openid profile email',
      redirect_uri: window.location.href.split('?')[0].replace(/\/$/, ""),
      prompt: 'consent',
      access_type: "offline",
      response_type: "code",
    });

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
    window.location.href = authUrl;
  }
  
  const exchageRefreshTokenForIDToken = (refreshToken) => {
    call_backend_api("auth_trade_refresh_token_for_id_token", "POST", {refresh_token: refreshToken, })
          .then((backend_reponse) => {
            if ('id_token' in backend_reponse ) {
              setGoogleUser(backend_reponse['id_token'])
              logger.log("LoginContext: User Logged In ", backend_reponse['id_token'])
            } else {
              logger.error("LoginContext: Something is wrong. id_token not received from backend", backend_reponse)
            }
          })
  }

  const checkForAuthenticationCodeInURLParamsAndSetCookie = () =>
  {
    if (window.location.search !== "") {
      const searchParams = new URLSearchParams(window.location.search)
      let responseAuthCode = searchParams.get('code')
      if (responseAuthCode !== "") {
        console.log(responseAuthCode)
        call_backend_api("auth_trade_auth_code_for_refresh_token", "POST", { auth_code: responseAuthCode, redirect_uri: window.location.href.split('?')[0].replace(/\/$/, "") })
          .then((backend_reponse) => {
            console.log(backend_reponse)
            if ('refresh_token' in backend_reponse ) {
              localStorage.setItem('refresh_token', backend_reponse['refresh_token']);
            } else {
              logger.error("LoginContext: Something is wrong. refresh_token not received from backend", backend_reponse)
            }
            window.location.href = window.location.href.split('?')[0].replace(/\/$/, "")
          });
      }
    }
  }
  const handleLogin = () => {
    logger.log("LoginContext: In handleLogin")
    const storedRefreshToken = localStorage.getItem('refresh_token');
    if (storedRefreshToken === null) {
      logger.log("LoginContext: No token found. Redirecting to google login")
      openGoogleLoginDialog()
    }
  }

  useEffect(() => {
    checkForAuthenticationCodeInURLParamsAndSetCookie()
  }, [])

  useEffect(() => {
    logger.log("LoginContext: Refresh token found. exchanging for id token")
    let storedRefreshToken = localStorage.getItem('refresh_token')
    if ((typeof googleUser === 'undefined' || googleUser === null) && localStorage.getItem('refresh_token') !== null) {
      exchageRefreshTokenForIDToken(storedRefreshToken)
    } else {
      setGoogleUser(null)
    }
  }, [])

  const handleLogout = () => {
    setGoogleUser(null);
    localStorage.removeItem('refresh_token');
    logger.log("User logged out.");
  };

  return (
    <LoginContext.Provider value={{ googleUser, handleLogin, handleLogout }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
