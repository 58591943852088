import React, { useContext, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { LoginContext } from "./LoginContext";

const LoginOverlay = () => {
  const { googleUser, handleLogin} = useContext(LoginContext);
  
  useEffect(() => {
    //renderLoginButton("overlayGoogleLoginButton")
  }, []);

  return (
    <div className={`w-full h-full absolute z-20 ${googleUser !== null ? 'hidden' : ''}`}>

        <div className='flex flex-col bg-slate-500 bg-opacity-70 w-full h-full'>
        <div className="font-semibold flex flex-col gap-4 mx-auto w-[80%] px-4 py-20 border items-center bg-blue-500  border-slate-200 rounded-lg
         text-slate-200" style={{ 'marginTop': '15vh' }}>

          <p className="" style={{ 'fontSize': '2vh' }}>
          כדי שנוכל לשלוח לך התראות לפני כניסת שבת, יש להתחבר עם חשבון גוגל
          <button onClick={handleLogin}  className="font-semibold flex m-auto px-4 py-2 mt-8 border gap-2 items-center bg-blue-600  border-slate-200 rounded-lg text-slate-200">
            <FcGoogle />
            התחבר עם גוגל
          </button>
            </p>
          </div>
          

        </div>
    </div>
  );
};

export default LoginOverlay;
